$(function() {
  //BURGER MENU
    jQuery(function($) {

        $('.scroll-down').on('click', function(e) {
            var target = $(this).attr('href');
            var speed = 1000;
            $("html, body").stop().animate({
                scrollTop: $(target).offset().top
            }, speed);
            e.preventDefault();
        });

          $('.scroll-to').on('click', function(e) {


            var target = $(this).attr('href');
            if(window.location.pathname == '/' && $(this).hasClass('nav-link')){
                target = target.replace('/','');
            }
            var speed = 1000;
            $("html, body").stop().animate({
                scrollTop: $(target).offset().top
            }, speed);
            e.preventDefault();

        });


        function hide_open_menu() {
            $('.burger').toggleClass('burger-open');
            if ($('.burger').hasClass('burger-open')) {


                $('.menu-burger').slideToggle('medium', function() {
                    if ($(this).is(':visible')) $(this).css('display', 'flex');
                });


            } else {
                $('.menu-burger').slideToggle('medium', function() {
                    if ($(this).is(':visible')) $(this).css('display', 'none');
                });
            }
        };

        $('.burger').click(function(e) {
            hide_open_menu();
        });

        $('.menu-burger a').on('click', function() {
            $('.menu-burger li').removeClass('active');
            $(this).parent().addClass('active');

            hide_open_menu();
            var target = $(this).attr('href');
            if(window.location.pathname == '/' && $(this).hasClass('nav-link')){
                target = target.replace('/','');
            }
            var speed = 1000;
            $("html, body").stop().animate({
                scrollTop: $(target).offset().top
            }, speed);
            e.preventDefault();

        });
    });


    //END BURGER MENU

    // Přiřaď výšku side_boxu dle výšky slieru
    var hero_height = $("#hero-carousel").height();
    $("#side_box").css("height",hero_height);

    // otočení šipky u toggle boxů
    $(".toggle-box").click(function(){
        $(this).find("h3").toggleClass("active");
    })

});












